import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useQuery } from '@apollo/react-hooks';
import { withApollo } from '../../apollo/lib';

import styles from './hero.module.scss';
import SearchFilters from '../search-filters/search-filters';
import useLanguage from '../_hooks/useLanguage';
import { setContext } from '../../helpers/gql';
import requestItemsCnt from '../../requests/query/auction/items-cnt';

const Hero = () => {
	const { t } = useLanguage();
	const [cnt, setCnt] = useState('...');

	const { loading, data } = useQuery(requestItemsCnt.gql.schema, {
		context: setContext(requestItemsCnt.properties.name)
	});

	useEffect(() => {
		if (loading === false && data && data.request) {
			setCnt(data.request.cnt);
		}
	}, [loading, data]);

	return (
		<div className={styles.default}>
			<div className={styles.overlay} />
			<div className="container">
				<div className="row">
					<div className={`col-12 ${styles.heroWrapper}`}>
						<div className={styles.textWrapper}>
							<h1>{t('heroTitle')}</h1>
							<p>{t('heroDescription')}</p>
						</div>
						<SearchFilters variation="hero" isTags={false} />
						<Link href="/drazbe"><a className="aa">{t('heroSeeAllAuctions').replace('{cnt}', cnt)} <i className="icon arrow-right-chevron white" /></a></Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withApollo({ ssr: true })(Hero);
