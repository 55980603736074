import React, { useState } from 'react';

import styles from './index.module.scss';
import Layout from '../components/layout/layout';
import Hero from '../components/hero/hero';
import TextBoxGrid from '../components/text-box-grid/text-box-grid';
import ItemList from '../components/item-list/item-list';
import TextBoxCta from '../components/text-box-cta/text-box-cta';
import Loader from '../components/loader/loader';
import useLanguage from '../components/_hooks/useLanguage';
import useUser from '../components/_hooks/useUser';

const Home = () => {
	const { t } = useLanguage();

	const { isMember } = useUser();

	/*
  'itemListTitle1' => 'Dražbe pred iztekom',
  'itemListTitle2' => 'Zamujene dražbe',
	'itemListTitle3' => 'Nove dražbe',
  'itemListTitle4' => 'Dražbe v karanteni',
	*/

	const [listLoadedCnt, setListLoadedCnt] = useState(0);

	return (
		<Layout variation="hero" bodyClass={styles.default}>
			<Hero />
			<TextBoxGrid />
			<div className={styles.itemLists}>		
				{listLoadedCnt === 0 && <Loader />}
				<ItemList title={t('itemListTitle3')} loader={false} onQueryEnd={() => setListLoadedCnt(listLoadedCnt + 1)} orderBy="modified_dtm DESC" perPage={20} link="/drazbe" carousel />
				<ItemList title={t('itemListTitle1')} loader={false} onQueryEnd={() => setListLoadedCnt(listLoadedCnt + 1)} filters={{ before_security_deposit: true }} orderBy="security_deposit_due_dt DESC" perPage={20} carousel />
				<ItemList title={t('itemListTitle4')} loader={false} onQueryEnd={() => setListLoadedCnt(listLoadedCnt + 1)} filters={{ after_security_deposit_before_event: true }} perPage={20} carousel />
				<ItemList title={t('itemListTitle2')} loader={false} onQueryEnd={() => setListLoadedCnt(listLoadedCnt + 1)} filters={{ auction_status_id: 40 }} orderBy="auction_start_dtm DESC" perPage={20} carousel />
			</div>
			{!isMember && <TextBoxCta />}
		</Layout>
	);
};

export default Home;
