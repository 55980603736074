import gql from 'graphql-tag';

const request = {
	properties: {
		name: 'auction>items_cnt'
	},

	data: null,

	gql: {
		schema: gql`
			query AuctionItemsCnt(
			  $subtype_id: [ID],
			  $type_id: ID,
			  $geo_location_level1_id: ID,
			  $geo_location_level2_id: ID,
			  $price: [String],
			  $auction_expire_soon: String,
			  $auction_share_sale_all: String,
			  $auction_sequence: [ID],
			  $auction_offer_type: [ID],
			  $related: Boolean,
			  $owned: Boolean
			) {
			  request(
			    subtype_id: $subtype_id,
			    type_id: $type_id,
			    geo_location_level1_id: $geo_location_level1_id,
			    geo_location_level2_id: $geo_location_level2_id,
			    price: $price,
			    auction_expire_soon: $auction_expire_soon,
			    auction_share_sale_all: $auction_share_sale_all,
			    auction_sequence: $auction_sequence,
			    auction_offer_type: $auction_offer_type,
			    related: $related,
			    owned: $owned
			  ) {
					cnt
			  }
			}
		`
	}
};

export default request;
